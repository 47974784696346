import React, { ReactElement } from "react";
import {
	DataObserveKey,
	FocusableComponent,
	IconElementProps,
	VisualComponent,
	f2u,
	cn,
	SrOnly,
	Link,
	ButtonProps,
	Button,
	TooltipProps,
	Tooltip,
	ButtonSize,
	ButtonStyle,
} from "@siteimprove/fancylib";
import { ActionMenu, ActionMenuProps } from "../../actions-and-controls/action-menu/action-menu";
import { useLabTranslations } from "../../../translations/translations";
import * as scss from "./horizontal-navigation.scss";

export type HorizontalNavigationProps = {
	appLogo?: {
		icon: ReactElement<IconElementProps>;
		title: string;
		"aria-label": string;
	} & Action;
	title?: string;
	actions: Array<
		(ActionMenuProps | ActionButton) & {
			"data-observe-key": string;
		}
	>;
	skipToContentId?: string;
	"aria-label"?: string;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export type ActionButton =
	| ActionButtonWithoutTooltip
	| (ActionButtonWithoutTooltip & {
			tooltip: TooltipProps["content"];
			tooltipPlacement?: TooltipProps["placement"];
	  });

type ActionButtonWithoutTooltip = Pick<ButtonProps, "children" | "onClick" | "href" | "openNew">;

interface ActionClick {
	"data-observe-key"?: string;
	onClick: () => void;
}

interface ActionLink {
	"data-observe-key"?: string;
	href: string;
	openNew?: boolean;
}

type Action = ActionClick | ActionLink;

export function HorizontalNavigation(props: HorizontalNavigationProps): JSX.Element {
	const { appLogo, title, actions, skipToContentId, className, style } = props;
	const i18nLab = useLabTranslations();

	function renderAppLogo(
		icon: ReactElement<IconElementProps>,
		title: string,
		ariaLabel: string,
		action: Action
	): JSX.Element {
		if ("href" in action) {
			return (
				<a
					className={scss.logo}
					data-observe-key={action["data-observe-key"]}
					href={action.href}
					title={title}
					rel={f2u(action.openNew && "noopener")}
					target={f2u(action.openNew && "_blank")}
					aria-label={ariaLabel}
				>
					{icon}
				</a>
			);
		}

		if ("onClick" in action) {
			return (
				<button
					className={scss.logo}
					data-observe-key={action["data-observe-key"]}
					type="button"
					title={title}
					aria-label={ariaLabel}
					onClick={() => action.onClick()}
				>
					{icon}
				</button>
			);
		}

		return <></>;
	}

	function renderActionMenus(): React.ReactNode {
		return actions.map((action) => {
			if ("children" in action) {
				const commonButtonProps = {
					...action,
					size: "large" as ButtonSize,
					variant: "borderless" as ButtonStyle,
				};

				if ("tooltip" in action) {
					return (
						<Tooltip
							variant={{ type: "interactive" }}
							content={action.tooltip!}
							placement={action.tooltipPlacement ?? "bottom"}
							key={action["data-observe-key"]}
						>
							<Button {...commonButtonProps} />
						</Tooltip>
					);
				}

				return <Button {...commonButtonProps} key={action["data-observe-key"]} />;
			}

			return (
				<ActionMenu
					{...action}
					key={action["data-observe-key"]}
					buttonProps={{
						...action.buttonProps,
						size: "large",
						variant: "borderless",
					}}
					hideChevron
					tooltipPlacement={action.tooltipPlacement ?? "bottom"}
				/>
			);
		});
	}

	return (
		<header
			data-component="HorizontalNavigation"
			data-observe-key={props["data-observe-key"]}
			role="banner"
			aria-label={props["aria-label"]}
			className={cn(scss.horizontalNavigation, className)}
			style={style}
		>
			{skipToContentId && (
				<SrOnly>
					<Link href={`#${skipToContentId}`} className={scss.skipToContent}>
						{i18nLab.skipToContent}
					</Link>
				</SrOnly>
			)}
			<div className={scss.heading}>
				{appLogo && renderAppLogo(appLogo.icon, appLogo.title, appLogo["aria-label"], appLogo)}
				{title && (
					<>
						<div className={scss.vr} />
						<div className={scss.title}>{title}</div>
					</>
				)}
			</div>

			{actions && <div>{renderActionMenus()}</div>}
		</header>
	);
}
